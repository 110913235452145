<template>
  <div class="h-100">
    <div class="flex-cloumn h-100">
      <div class="tab">
        <!-- tab栏 -->
        <div
          class="tab-item"
          v-for="item in tabMenu"
          :key="item.id"
          :class="curTab === item.id ? 'tab-item-active' : ''"
          @click="curTab = item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 筛选表单 -->
      <div class="btn-wrap flex-row space-between">
        <div
          class="creatNewBtn"
          @click="openDialog(curTab === 1 ? 'addCoupon' : 'addCode')"
        >
          {{ curTab === 1 ? "新建优惠券" : "新建二维码" }}
        </div>
        <el-input
          v-if="curTab === 2"
          v-model="tableFilter2.welfareName"
          style="width: 226px"
          placeholder="搜索关键字"
          size="mini"
        ></el-input>
      </div>
      <div v-if="curTab === 1" class="select-wrap">
        <div>
          <el-select
            v-model="tableFilter1.discountsType"
            style="width: 100px"
            class="select-item"
            size="mini"
            placeholder="优惠设置"
          >
            <el-option
              v-for="(option, index) in selectArr.type"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>

          <el-select
            v-model="tableFilter1.pushLocal"
            class="select-item"
            style="width: 100px"
            size="mini"
            placeholder="推送展区"
          >
            <el-option
              v-for="(option, index) in selectArr.zone"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="tableFilter1.sellStatus"
            class="select-item"
            style="width: 100px"
            size="mini"
            placeholder="发行状态"
          >
            <el-option
              v-for="(option, index) in selectArr.status"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
        </div>
        <!-- <el-input
          style="width: 226px"
          placeholder="搜索关键字"
          size="mini"
        ></el-input> -->
      </div>
      <div v-if="curTab === 1" class="flex-row space-between">
        <div>
          <span>发行日期：</span>
          <el-date-picker
            v-model="tableFilter1.date"
            type="daterange"
            size="mini"
            value-format="yyyy-MM-dd"
            range-separator="-"
            style="width: 280px"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <span style="margin-left: 10px">失效日期：</span>
          <el-date-picker
            v-model="tableFilter1.invalidDate"
            type="daterange"
            value-format="yyyy-MM-dd"
            size="mini"
            range-separator="-"
            style="width: 280px"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div>
          <el-select
            v-model="tableFilter1.sort"
            class="select-item"
            style="width: 100px"
            size="mini"
            placeholder="排序"
          >
            <el-option
              v-for="(option, index) in selectArr.sort"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
          <el-select
            disabled
            class="select-item"
            style="width: 100px"
            size="mini"
            placeholder="批量管理"
          >
            <el-option
              v-for="(option, index) in list"
              :key="index"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div v-if="curTab === 1" class="row2 flex-1">
        <el-card class="row2-card" v-loading="loading">
          <!-- 表头 -->
          <div class="flex-row table-header" style="padding-left: 60px">
            <div style="width: 156px; text-align: left">名称</div>
            <div style="width: 160px">简要</div>
            <div style="width: 250px">发行日期</div>
            <div style="width: 100px">失效日期</div>
            <div style="width: 100px">操作</div>
            <div style="width: 80px; padding-left: 10px">状态</div>
          </div>
          <!-- 行数据 -->
          <div
            class="item"
            @click="openDialog('addCoupon', item)"
            v-for="(item, i) in list"
            :key="i"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <el-checkbox></el-checkbox>
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="flex-row space-around flex-1">
              <!-- 名称 -->
              <div class="item-title" style="width: 20%">
                {{ item.discountsName }}
              </div>
              <!-- 折扣以及位置 -->
              <div class="item-message" style="width: 18%">
                {{ item.discountsType === 1 ? "满减" : "折扣" }}
                / {{ item.pushLocal === 1 ? "公告" : "直接发放" }}
              </div>
              <!-- 发行日期 -->
              <div class="item-date" style="width: 20%; text-align: center">
                {{ item.sellTime ? item.sellTime : "暂无时间" }}
              </div>
              <!-- 过期日期 -->
              <div class="item-date" style="width: 15%; text-align: center">
                {{
                  item.failureEndTime === "2050-01-01"
                    ? "无限期"
                    : item.failureEndTime
                }}
              </div>
              <!-- 发售状态按钮 -->
              <div
                @click.stop="saleOperation(item.id, item.sellStatus, item)"
                class="item-check-true"
                :style="{
                  background: item.sellStatus ? '#fff' : 'rgb(127, 118, 189)',
                  color: item.sellStatus ? '#000' : '#fff',
                }"
              >
                {{ ["立即发行", "停止", "新建重发"][item.sellStatus] }}
              </div>
              <!-- 发售状态 -->
              <div class="item-state" style="width: 10%">
                <div
                  class="state"
                  :style="{ background: statusArr[item.sellStatus].bg }"
                >
                  {{ item.sellStatusName }}
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div v-else class="row2 flex-1">
        <el-card class="row2-card" v-loading="loading">
          <!-- 表头 -->
          <div class="flex-row table-header" style="padding-left: 60px">
            <div style="width: 255px; text-align: left">名称</div>
            <div style="width: 260px">内容</div>
            <div style="width: 100px" class="relative-left-25">开始日期</div>
            <div style="width: 130px" class="relative-left-35">结束日期</div>
            <div style="width: 100px" class="relative-left-10">操作</div>
          </div>
          <!-- 行数据 -->
          <div
            class="item"
            @click="openDialog('addCode', item)"
            v-for="(item, i) in list2"
            :key="i"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <el-checkbox></el-checkbox>
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="flex-row space-around flex-1">
              <div class="item-title shenglue-1" style="width: 215px">
                {{ item.welfareName }}
              </div>
              <div class="item-message shenglue-1" style="width: 280px">
                <span class="m-l-10"
                  >总量：{{ item.totalCount
                  }}{{ item.type === 2 ? "点券" : "金币" }}</span
                >
                每人领取数量：{{ item.everyoneCount
                }}{{ item.type === 2 ? "点券" : "金币" }}
              </div>
              <!-- 发行日期 -->
              <div
                class="item-date shenglue-1"
                style="width: 100px; text-align: center"
              >
                {{ item.startTime.substring(0, 11) }}
              </div>
              <!-- 过期日期 -->
              <div
                class="item-date shenglue-1"
                style="width: 100px; text-align: center"
              >
                {{ item.endTime.substring(0, 11) }}
              </div>
              <div
                @click.stop="deleteCode(item.id)"
                class="item-position shenglue-1"
                style="width: 50px"
              >
                <div class="position-btn delete-btn">删除</div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="row3">
      <el-pagination
        :total="total2"
        :current-page.sync="tableFilter2.currPage"
        :page-size.sync="tableFilter2.limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <add-coupon
      ref="addCoupon"
      @getCouponList="getDiscountList"
      :detail="couponDetail"
      :isNew="isNew"
    />
    <add-code
      ref="addCode"
      @get2dCodeList="get2dCodeList"
      :detail="codeDetail"
    />
  </div>
</template>
<script>
import addCoupon from "./common/component/addCoupon.vue";
import addCode from "./common/component/add2dCode";
export default {
  components: {
    addCoupon,
    addCode,
  },
  data() {
    // 筛选选择框集合
    this.selectArr = {
      type: [
        { label: "全部优惠", value: null },
        { label: "满减优惠", value: 1 },
        { label: "折扣优惠", value: 2 },
      ],
      zone: [
        { label: "全部展区", value: null },
        { label: "公告", value: 1 },
        { label: "直接发放", value: 2 },
      ],
      status: [
        { label: "全部状态", value: null },
        { label: "已发售", value: 1 },
        { label: "保存未发行", value: 0 },
        { label: "停止发布", value: 2 },
      ],
      sort: [
        { label: "时间升序", value: "1" },
        { label: "时间降序", value: "2" },
      ],
    };
    this.statusArr = [
      { label: "未发售", bg: "rgb(215, 213, 223)" },
      { label: "已发售", bg: "rgb(139, 209, 203)" },
      { label: "停售", bg: "rgb(215, 213, 223)" },
    ];
    return {
      loading: false,
      // tab栏
      curTab: 1,
      isNew: false,
      couponDetail: {},
      codeDetail: {},
      tabMenu: [
        { name: "优惠券", id: 1 },
        { name: "二维码发行", id: 2 },
      ],
      // 搜索
      tableFilter1: {
        date: [],
        invalidDate: [],
        discountsType: null,
        pushLocal: null,
        sort: null,
        selleStatus: null,
        sellStartDate: null,
        sellEndDate: null,
        failureStartTime: null,
        failureEndTime: null,
        currPage: 1,
        limit: 50,
      },
      tableFilter2: {
        currPage: 1,
        limit: 50,
        welfareName: "",
      },
      list: [],
      total: 0,
      list2: [],
      total2: 0,
    };
  },
  created() {
    this.getDiscountList();
    // this.get2dCodeList();
  },
  methods: {
    async getDiscountList() {
      this.loading = true;
      const data = await this.$api.shop
        .getDiscountList(this.dealParams(this[`tableFilter${this.curTab}`]))
        .finally(() => {
          this.loading = false;
        });
      this.list = data.data;
      this.total = data.count;
    },
    async get2dCodeList() {
      this.loading = true;
      const data = await this.$api.shop
        .get2dCodeList(this.tableFilter2)
        .finally(() => {
          this.loading = false;
        });
      this.list2 = data.data;
      this.total2 = data.count;
    },
    dealParams(obj) {
      const params = JSON.parse(JSON.stringify(obj));
      if (params.date && params.date.length) {
        params.sellStartDate = params.date[0];
        params.sellEndDate = params.date[1];
      }
      if (params.invalidDate && params.invalidDate.length) {
        params.failureStartTime = params.invalidDate[0];
        params.failureEndTime = params.invalidDate[1];
      }
      delete params.date;
      delete params.invalidDate;
      return params;
    },
    openDialog(ref, detail = null, isNew = null) {
      this.isNew = isNew;
      this[ref === "addCode" ? "codeDetail" : "couponDetail"] = detail;
      this.$refs[ref].showDialog = true;
    },
    saleOperation(id, sellStatus, item = null) {
      if (sellStatus === 2) {
        this.openDialog("addCoupon", item, true);
      } else {
        this.$confirm(
          `您确定要${sellStatus ? "停止" : "发行"}此${
            this.curTab === 1 ? "优惠券" : "活动"
          }吗？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(async () => {
          await this.$api.shop.couponStatusOperation({
            id: id,
            sellStatus: sellStatus === 1 ? 2 : 1,
          });
          this.getDiscountList();
        });
      }
    },
    checkParams(item) {
      const obj = JSON.parse(JSON.stringify(item));
      obj.sellStatus = 1;
      delete obj.id;
      if (obj.discountsType === 1) {
        delete obj.totalPrice;
        delete obj.mitigatePrice;
        obj.discountsDeduction = 0;
      } else {
        delete obj.discount;
      }
      return obj;
    },
    deleteCode(id) {
      this.$confirm("此操作将永久删除该二维码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.$api.shop.delete2dCode(id);
        this.get2dCodeList();
      });
    },
    handleSizeChange(size) {
      this.tableFilter2.limit = size
      this.get2dCodeList()
    },
    handleCurrentChange(page) {
      this.tableFilter2.currPage = page
      this.get2dCodeList()
    }
  },
  watch: {
    tableFilter1: {
      handler() {
        this.getDiscountList();
      },
      deep: true,
    },
    tableFilter2: {
      handler() {
        this.get2dCodeList();
      },
      deep: true,
    },
    curTab(newVal) {
      if (newVal === 2) {
        this.get2dCodeList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./common/style/shop.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}
</style>