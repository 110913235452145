<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px">
        {{ form.discountsName }}
      </span>
      <!-- <el-button class="slot-button" size="mini">保存并复制</el-button> -->
    </div>
    <div class="dialog">
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>名称
        </div>
        <el-input
          v-model="form.discountsName"
          style="width: 212px"
          size="mini"
          placeholder="请输入名称"
        ></el-input>
      </div>
      <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>优惠设置
        </div>
        <div>
          <div class="m-b-10">
            <el-select
              v-model="form.discountsRestrict"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="(option, index) in selectArr.discountsRestrict"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span class="m-lr-10">购买</span>
            <el-select
              v-model="form.productIdList"
              multiple
              size="mini"
              placeholder="请选择"
              style="width: 300px"
            >
              <el-option
                v-for="option in tempGoodsList"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span class="m-lr-10">时</span>
          </div>
          <div class="flex-row flex-ac">
            <span class="m-r-10">享有优惠：</span>
            <el-button-group
              class="m-r-10"
              style="overflow: hidden; border-radius: 10px"
            >
              <el-button
                round
                @click="form.discountsType = 1"
                :type="form.discountsType === 1 ? 'primary' : ''"
                size="mini"
                >折扣</el-button
              >
              <el-button
                round
                @click="form.discountsType = 2"
                :type="form.discountsType === 2 ? 'primary' : ''"
                size="mini"
                >满减</el-button
              >
            </el-button-group>
            <div v-if="form.discountsType === 2">
              <span class="m-lr-10">满</span>
              <el-input
                v-model="form.totalPrice"
                style="width: 60px"
                size="mini"
              ></el-input>
              <span class="m-lr-10">减</span>
              <el-input
                v-model="form.mitigatePrice"
                style="width: 60px"
                size="mini"
              ></el-input>
              <el-button-group
                class="m-l-10"
                style="overflow: hidden; border-radius: 10px"
              >
                <el-button
                  round
                  @click="form.discountsDeduction = 1"
                  :type="form.discountsDeduction === 1 ? 'primary' : ''"
                  size="mini"
                  >点券</el-button
                >
                <el-button
                  round
                  @click="form.discountsDeduction = 2"
                  :type="form.discountsDeduction === 2 ? 'primary' : ''"
                  size="mini"
                  >金币</el-button
                >
              </el-button-group>
            </div>
            <div v-else>
              <el-input
                v-model="form.discount"
                class="m-lr-10"
                style="width: 60px"
                size="mini"
              >
                <span slot="suffix" class="suffix">折</span></el-input
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>优惠次数
        </div>
        <el-button
          size="mini"
          @click="
            form.discountsQuantity = form.discountsQuantity === -1 ? 0 : -1
          "
          :type="form.discountsQuantity === -1 ? 'primary' : ''"
          >无限</el-button
        >
        <el-input
          :disabled="form.discountsQuantity === -1"
          v-model="form.discountsQuantity"
          style="width: 70px"
          size="mini"
        >
          <span slot="suffix" class="suffix">次</span>
        </el-input>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>失效日期
        </div>
        <el-button
          @click="
            form.failureEndTime =
              form.failureEndTime === '2050-01-01' ? '' : '2050-01-01'
          "
          :type="form.failureEndTime === '2050-01-01' ? 'primary' : ''"
          size="mini"
          >无限期</el-button
        >
        <el-date-picker
          :disabled="form.failureEndTime === '2050-01-01'"
          v-model="form.failureEndTime"
          size="mini"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>推送位置
        </div>
        <el-select
          v-model="form.pushLocal"
          class="m-r-20"
          size="mini"
          placeholder="请选择"
        >
          <el-option
            v-for="(option, index) in selectArr.pushLocal"
            :key="index"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
        <choice-image
          style="margin-left: 20px"
          @surePush="changeImage"
          :viewUrl="bannerUrl"
        />
        <!-- <el-button size="mini" type="primary">选择配图</el-button> -->
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>优惠标签
        </div>
        <el-input
          v-model="form.discountsLabel"
          style="width: 100px"
          size="mini"
          maxlength="4"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>活动说明
        </div>
        <el-input
          v-model="form.discountsExplain"
          style="width: 430px"
          size="mini"
          type="textarea"
          placeholder="请输入说明"
        ></el-input>
        <span class="remark-text p-rb-0">（必须提及赠品内容，建议60字内）</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="detail"
        :loading="loadingArr[3]"
        size="mini"
        plain
        type="danger"
        @click="deleteCoupon"
        >删除</el-button
      >
      <el-button
        :loading="loadingArr[0]"
        size="mini"
        type="danger"
        @click="addCoupon(0)"
        v-if="!detail || (detail && detail.sellStatus === 0)"
        >保存未发行</el-button
      >
      <el-button
        :loading="loadingArr[2]"
        size="mini"
        type="info"
        plain
        @click="addCoupon(2)"
        v-if="!detail || (detail && detail.sellStatus !== 2)"
        >停止</el-button
      >
      <el-button
        :loading="loadingArr[1]"
        size="mini"
        type="primary"
        @click="addCoupon(1)"
        v-if="!detail || (detail && detail.sellStatus === 0)"
        >立即发行</el-button
      >
      <el-button
        :loading="loadingArr[1]"
        size="mini"
        type="primary"
        @click="addCoupon(1, true)"
        v-if="detail && detail.sellStatus === 2 && isNew"
        >新建重售</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
export default {
  props: {
    detail: {
      default: null,
    },
    isNew: {
      default: null,
    },
  },
  components: {
    choiceImage,
  },
  data() {
    this.selectArr = {
      discountsRestrict: [
        { label: "所有人", value: 1 },
        { label: "首充用户", value: 2 },
      ],
      pushLocal: [
        { label: "公告", value: 1 },
        { label: "直接发放", value: 2 },
      ],
    };
    return {
      showDialog: false,
      goodsList: [],
      form: {
        bannerId: "",
        discountsName: "",
        discountsRestrict: 1,
        productIdList: [],
        discountsType: 1,
        discount: 0,
        totalPrice: 0,
        mitigatePrice: 0,
        discountsQuantity: -1,
        failureEndTime: "2050-01-01",
        pushLocal: 1,
        discountsLabel: "",
        discountsExplain: "",
        discountsDeduction: 1,
      },
      loadingArr: [false, false, false, false],
      bannerUrl: "",
    };
  },
  methods: {
    async getShopList() {
      const data = await this.$api.shop.getShopList({ page: 1, limit: 100 });
      this.goodsList = data.data;
    },
    async addCoupon(op, isNew) {
      this.$set(this.loadingArr, op, true);
      this.form.sellStatus = op;
      await this.$api.shop[this.detail && !isNew ? "editCoupon" : "addCoupon"](
        this.checkParams(isNew)
      ).finally(() => {
        this.$set(this.loadingArr, op, false);
      });
      this.showDialog = false;
      this.$emit("getCouponList");
    },
    checkParams(isNew) {
      const obj = JSON.parse(JSON.stringify(this.form));
      if (isNew) {
        delete obj.id
      }
      if (obj.discountsType === 1) {
        delete obj.totalPrice
        delete obj.mitigatePrice
        obj.discountsDeduction = 0
      } else {
        delete obj.discount;
      }
      return obj
    },
    async getCuoponDetail(id) {
      const data = await this.$api.shop.getCouponDetail(id);
      data.couponCount && (this.changeForm.couponCount = data.couponCount);
      data.productExhibitionRelevanceBoList = data.productExhibitionRelevanceBoList.map(
        (item) => {
          return item.exhibitionId;
        }
      );
      Object.assign(this.form, data);
    },
    changeImage(model) {
      this.form.bannerId = model.id;
      this.bannerUrl = model.picturePath;
    },
    deleteCoupon() {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.$set(this.loadingArr, 3, true);
        await this.$api.shop.deleteCoupon(this.form.id).finally(() => {
          this.$set(this.loadingArr, 3, false);
          this.showDialog = false;
        });
        this.$emit("getCouponList");
      });
    },
  },
  watch: {
    showDialog(newVal) {
      if (newVal) {
        this.getShopList();
        this.detail && (this.form = JSON.parse(JSON.stringify(this.detail)));
      } else {
        this.form = this.$options.data().form;
        this.bannerUrl = "";
      }
    },
  },
  computed: {
    tempGoodsList() {
      return this.goodsList.map((item) => {
        return {
          value: item.id,
          label: item.productName,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
</style>