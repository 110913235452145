<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px">
        {{ form.welfareName }}</span
      >
    </div>
    <div class="dialog">
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>名称
        </div>
        <el-input
          v-model="form.welfareName"
          style="width: 200px"
          size="mini"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>时间
        </div>
        <el-date-picker
          v-model="form.date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>类型
        </div>
        <el-button-group style="overflow: hidden; border-radius: 10px">
          <el-button @click="form.type = 2" round :type="form.type === 2 ? 'primary' : ''" size="mini">点券</el-button>
          <el-button @click="form.type = 3" round :type="form.type === 3 ? 'primary' : ''" size="mini">金币</el-button>
          <!-- <el-button
            round
            @click="form.isTransparency = true"
            :type="form.isTransparency ? 'primary' : ''"
            size="mini"
            >剧透</el-button
          > -->
        </el-button-group>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>内容
        </div>
        <span>总量：</span>
        <el-input
          v-model="form.totalCount"
          style="width: 100px; margin-right: 20px"
          size="mini"
          type="number"
        ></el-input>

        <span>每人领取数量：</span>
        <el-input
          v-model="form.everyoneCount"
          style="width: 100px; margin-right: 20px"
          size="mini"
          type="number"
        ></el-input>
      </div>
      <div v-if="detail" class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>二维码
        </div>
        <div style="text-align:center">
          <img v-if="form.qrCodeImg" :src="form.qrCodeImg" class="avatar" />
          <span class="remark-text" style="font-size: 15px">右键点击下载</span>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="form.saleStatus !== 1">
      <!-- <el-button v-if="id" size="mini" plain type="danger" @click="deleteScript"
        >删除</el-button
      > -->
      <el-button
        :loading="loading"
        size="mini"
        type="primary"
        @click="add2dCode"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { formRule, deepCopy } from "@tools";
export default {
  props: {
    detail: {
      default: null,
    },
  },
  data() {
    this.formRule = [
      {
        label: "welfareName",
        name: "二维码名称",
        rule: {
          required: true,
        },
      },
      {
        label: "totalCount",
        name: "总量",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "everyoneCount",
        name: "每人领取数量",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "startTime",
        name: "开始时间",
        rule: {
          required: true,
        },
      },
      {
        label: "endTime",
        name: "结束时间",
        rule: {
          required: true,
        },
      },
    ];
    return {
      showDialog: false,
      loading: false,
      form: {
        endTime: "",
        everyoneCount: 1,
        startTime: "",
        totalCount: 1,
        welfareName: "",
        type: 2,
        date: [],
      },
      accessToken: localStorage.getItem("access_token"),
    };
  },
  created() {},
  methods: {
    async add2dCode() {
      const params = deepCopy(this.form);
      if (params.date && params.date.length) {
        params.startTime = params.date[0];
        params.endTime = params.date[1];
      }
      if (!formRule(params, this.formRule)) return;
      if (+params.totalCount < +params.everyoneCount) {
        this.$message.warning("总量不得低于每人领取量！");
        return;
      }
      delete params.qrCodeImg;
      this.loading = true;
      await this.$api.shop[this.detail ? "edit2dCode" : "add2dCode"](
        params
      ).finally(() => {
        this.loading = false;
      });
      this.showDialog = false;
      this.$message.success(this.detail ? "修改成功！" : "创建成功！");
      this.$emit("get2dCodeList");
    },
    beforeUpload(file) {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!(isPNG || isJPG || isGIF)) {
        this.$message.error("上传图片只能是 PNG或JPG, GIF格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isPNG || isJPG || isGIF) && isLt2M;
    },
    uploadSuccess(res) {
      this.form.userHeadPicturePath = res.url;
    },
  },
  computed: {},
  watch: {
    showDialog(newVal) {
      if (newVal) {
        Object.assign(
          this.form,
          this.detail ? this.detail : this.$options.data().form
        );
        if (!this.detail) {
          this.$delete(this.form, "id");
        } else {
          this.form.date = [this.detail.startTime, this.detail.endTime];
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
.avatar {
  width: 198px;
  height: 198px;
}
</style>